import axios from "axios";
import jwtDecode from "jwt-decode";
import { WEB_GTWY_API_URL } from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { getISOformatString } from "./helper";
import moment from "moment";
export const getRequestHeaders = async () => {
  const header = { "Content-Type": "application/json" };
  const access_token = window.localStorage.getItem("access_token");
  if (access_token) {
    header["Authorization"] = `Bearer ${access_token}`;
  }
  return header;
};

export const handleUpdatePayoutStatus = async (payout_webhook) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/webhook-update`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = {
    ...payout_webhook,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addVaPayinToVirtualAccount = async (virtual_account_webhook) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/webhook-update`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = {
    ...requestData,
    ...virtual_account_webhook,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getFilterQueryUrl = (filterQueryArr) =>
  filterQueryArr
    .filter(
      ({ filter_value, filter_key }) =>
        filter_key !== "" &&
        filter_value !== "" &&
        filter_key !== null &&
        filter_value !== null &&
        filter_key !== undefined &&
        filter_value !== undefined
    )
    .reduce((prevVal, currArrVal, currIdx, arr) => {
      const { filter_key, filter_value } = currArrVal;

      if (currIdx === arr.length - 1) {
        return `${prevVal}${filter_key}=${filter_value}`;
      } else {
        return prevVal + `${filter_key}=${filter_value}&`;
      }
    }, "");

const createRequest = async () => {
  const request = {};

  return request;
};
const isValidToken = (access_token) => {
  if (!access_token) {
    return false;
  }
  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const getOrgAccDetails = async () => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllOrganization = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllKyb = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/kyb`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const forgotPasswordEmail = async (email) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user/forgot-password`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = email;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const resetPasswordWithCode = async (reset_code, password) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user/password-reset-with-code`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.password_reset_code = reset_code;
  requestData.new_password = password;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const logoutSession = async (session_id) => {
  const url = `${WEB_GTWY_API_URL}/logout/${session_id}`;
  const response = await axios.delete(url);
  return response.data;
};

export const addAccount = async ({
  email,
  first_name,
  last_name,
  password,
  phone_number,
  is_admin,
  user_type,
  password_reset_required,
}) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user/add`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = `${email}@finmo.net`;
  requestData.first_name = first_name;
  requestData.last_name = last_name;
  requestData.password = password;
  requestData.phone_number = phone_number;
  requestData.user_type = user_type;
  requestData.is_admin = is_admin;
  requestData.password_reset_required = password_reset_required;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgSpecificUsers = async (org_id, filterQueryArr = []) => {
  let URL = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}/user`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }

  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getAllCheckout = async (filterQueryArr = [], org_id, env) => {
  let URL = `${WEB_GTWY_API_URL}/torc/checkout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (!!env) requestHeaders["x-env"] = env;
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(URL, { headers: requestHeaders });
  const res = response.data;
  return res;
};

export const getAllTransactionAlerts = async (
  filterQueryArr = [],
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-alert`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getTransactionAlert = async (alert_id, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-alert/${alert_id}/transactions`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

//Cubejs endpoint for TM alert analytics
export const getTransactionAlertAnalytics = async (
  data,
  env = "production"
) => {
  let url = `https://cube.finmo.net/cubejs-api/v1/load`;
  const requestHeaders = {};
  requestHeaders[
    "Authorization"
  ] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjEwMjYxMjcsImV4cCI6MTc1MjU4MzcyN30.E6yoYKTPLNWYjbeQ7TLgUO0ii8nfjhWG0hudB1cAX44`;
  const response = await axios.post(url, data, { headers: requestHeaders });
  return response.data;
};

export const updateTransactionAlert = async (
  alert_id,
  data,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-alert/${alert_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  let requesData = await createRequest();
  requesData = { ...requesData, ...data };
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resumePayout = async (payout_id, org_id, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/payout/${payout_id}/resume-payout`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  let requesData = await createRequest();
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const failPayout = async (payout_id, org_id, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/payout/${payout_id}/fail-payout`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  let requesData = await createRequest();
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const revertFailedPayoutFees = async (
  payout_id,
  org_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout/${payout_id}/revert-fees`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  let requesData = await createRequest();
  const response = await axios.post(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const reviewPayin = async (
  action,
  payin_id,
  org_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin/${payin_id}/backoffice-action`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  let requesData = await createRequest();
  requesData.action = action;
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllScreeningAlerts = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/organization/screening-alert`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getScreeningAlert = async (alert_id) => {
  let url = `${WEB_GTWY_API_URL}/organization/screening-alert/${alert_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateScreeningAlert = async (alert_id, data) => {
  let url = `${WEB_GTWY_API_URL}/organization/screening-alert/${alert_id}`;
  const requestHeaders = await getRequestHeaders();
  let requesData = await createRequest();
  requesData = { ...requesData, ...data };
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllAdjustment = async (
  filterQueryArr = [],
  org_id = "",
  env = "production"
) => {
  let URL = `${WEB_GTWY_API_URL}/torc/adjustment`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const getAdjustmentById = async (adjust_id, env = "production") => {
  const URL = `${WEB_GTWY_API_URL}/torc/adjustment/${adjust_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const addNewAdjustment = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/adjustment`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  requestHeaders["x-env"] = obj.env;
  const requestData = await createRequest();
  requestData.wallet_id = obj.wallet_id;
  requestData.amount = obj.amount;
  requestData.currency = obj.currency;
  requestData.type = obj.type;
  requestData.description = obj.description;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllFeesAdjustment = async (
  filterQueryArr = [],
  org_id = "",
  env = "production"
) => {
  let URL = `${WEB_GTWY_API_URL}/torc/fees-adjustment`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const getFeesAdjustmentById = async (
  fees_adjustment_id,
  env = "production"
) => {
  const URL = `${WEB_GTWY_API_URL}/torc/fees-adjustment/${fees_adjustment_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const addNewFeesAdjustment = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/fees-adjustment`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  requestHeaders["x-env"] = obj.env;
  const requestData = await createRequest();
  requestData.wallet_id = obj.wallet_id;
  requestData.amount = obj.amount;
  requestData.currency = obj.currency;
  requestData.type = obj.type;
  requestData.is_settled = obj.is_settled;
  requestData.is_to_be_included_in_mm = obj.is_to_be_included_in_mm;
  requestData.event_id = obj.event_id || null;
  requestData.event_name = obj.event_name;
  requestData.description = obj.description || null;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllCustomer = async (
  filterQueryArr = [],
  org_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/customer`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }

  requestHeaders["x-env"] = env;
  const response = await axios.get(url, { headers: requestHeaders });
  const res = response.data;
  return res;
};

export const getAllVirtualAcc = async (filterQueryArr = [], org_id, env) => {
  let URL = `${WEB_GTWY_API_URL}/torc/virtual-account`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (!!env) requestHeaders["x-env"] = env;
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(URL, { headers: requestHeaders });
  const res = response.data;
  return res;
};

export const getAllWallet = async (
  filterQueryArr = [],
  org_id,
  env = "production"
) => {
  let URL = `${WEB_GTWY_API_URL}/torc/wallet`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  requestHeaders["x-env"] = env;
  const response = await axios.get(URL, { headers: requestHeaders });
  const res = response.data;
  return res;
};

export const getAllWft = async (filterQueryArr = [], env) => {
  let URL = `${WEB_GTWY_API_URL}/torc/wallet-fund-transfer`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (!!env) requestHeaders["x-env"] = env;
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const getOrgSpecificWft = async (org_id, filterQueryArr = [], env) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-fund-transfer`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders();
  if (!!env) requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getWftById = async (wft_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-fund-transfer/${wft_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllPayin = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getInvestmentAccountData = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/org-bank-interface-config`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateInvestmentAccountData = async (
  org_bank_interface_config_id,
  org_id,
  body
) => {
  let url = `${WEB_GTWY_API_URL}/tos/org-bank-interface-config/${org_bank_interface_config_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};
export const rejectOrgBankInterface = async (
  org_bank_interface_config_id,
  org_id
) => {
  let url = `${WEB_GTWY_API_URL}/tos/org-bank-interface-config/reject/${org_bank_interface_config_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.patch(
    url,
    {},
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const fetchTrxnByPattern = async (
  category = "",
  pattern = "",
  filterQueryArr = []
) => {
  let url = `${WEB_GTWY_API_URL}/pattern-search/category/${category}/search?q=${pattern}`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}&${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const markTxnAsDisputed = async (data) => {
  let url = `${WEB_GTWY_API_URL}/torc/dispute`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.disputed_event_id = data.disputed_event_id;
  requestData.remark = data.remark;
  requestData.disputed_event_type = data.disputed_event_type;
  requestData.is_amount_to_be_reserved = data.is_amount_to_be_reserved;
  requestData.type = data.type;
  requestData.amount = data.amount;
  requestData.reason_for_dispute = data.reason_for_dispute;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllDisputes = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/dispute`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getDisputeById = async (dispute_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/dispute/${dispute_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resolveDispute = async (dispute_id, body) => {
  let url = `${WEB_GTWY_API_URL}/torc/dispute/${dispute_id}/resolve`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

export const changeDisputeStatus = async (dispute_id, body) => {
  let url = `${WEB_GTWY_API_URL}/torc/dispute/${dispute_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgSpecificPayin = async (org_id, limit, page) => {
  try {
    let URL = `${WEB_GTWY_API_URL}/torc/payin`;
    if (limit && page) {
      URL = `${WEB_GTWY_API_URL}/torc/payin?limit=${limit}&page=${page}`;
    }
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getAllPayinMethod = async (filterQueryArr = [], env) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin-method`;
  if (filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (env) {
    requestHeaders["x-env"] = env;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const addPayinMethod = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin-method`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payin_method_name = obj.pm_name;
  requestData.payin_method_logo = obj.pm_logo;
  requestData.connector_id = obj.connector_id;
  requestData.country = obj.country;
  requestData.currency = obj.currency;
  requestData.payin_method_category = obj.category;
  requestData.payin_method_class = obj.pm_class;
  requestData.payin_method_group = obj.pm_group;
  requestData.min_amount = obj.min_amount;
  requestData.max_amount = obj.max_amount;
  requestData.payin_method_description = obj.pm_description;
  requestData.is_payin_allowed = obj.is_payin_allowed;
  requestData.is_va_allowed = obj.is_va_allowed;
  requestData.is_contract_required = obj.is_contract_required;
  requestData.is_enabled_by_default = obj.is_enabled_by_default;
  requestData.is_partially_refundable = obj.is_partially_refundable;
  requestData.is_over_refundable = obj.is_over_refundable;
  requestData.is_cancellable = obj.is_cancellable;
  requestData.is_refundable = obj.is_refundable;
  requestData.is_expirable = obj.is_expirable;
  requestData.is_sender_validation_enabled = obj.is_sender_validation_enabled;
  requestData.is_payin_method_available_24_7 =
    obj.is_payin_method_available_24_7;
  requestData.is_activation_required = obj.is_activation_required;
  requestData.max_expiration_time_in_seconds = obj.max_expiration_time_in_seconds
    ? Number(obj.max_expiration_time_in_seconds)
    : null;
  requestData.default_expiry_time_seconds = obj.default_expiry_time
    ? Number(obj.default_expiry_time)
    : null;
  requestData.payin_method_display_name = obj.pm_display_name;
  requestData.start_time = new Date(obj.start_time).toLocaleTimeString();
  requestData.end_time = new Date(obj.end_time).toLocaleTimeString();
  requestData.excluded_day_list = obj.excluded_days_of_week;
  requestData.payin_method_additional_logo = obj.pm_additional_logo
    ? JSON.parse(obj.pm_additional_logo)
    : {};
  requestData.payin_method_config = obj.pm_config
    ? JSON.parse(obj.pm_config)
    : {};
  requestData.payin_method_param = obj.pm_param ? JSON.parse(obj.pm_param) : [];
  requestData.payin_method_instruction = obj.pm_instruction
    ? JSON.parse(obj.pm_instruction)
    : [];
  requestData.activation_config_param = obj.activation_config_param
    ? JSON.parse(obj.activation_config_param)
    : null;
  requestData.is_multiple_refund_allowed = obj.is_multiple_refund_allowed;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editPayinMethod = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin-method/${obj.pm_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payin_method_name = obj.pm_name;
  requestData.payin_method_logo = obj.pm_logo;
  requestData.connector_id = obj.connector_id;
  requestData.country = obj.country;
  requestData.currency = obj.currency;
  requestData.payin_method_category = obj.category;
  requestData.payin_method_class = obj.pm_class;
  requestData.payin_method_group = obj.pm_group;
  requestData.min_amount = obj.min_amount;
  requestData.max_amount = obj.max_amount;
  requestData.payin_method_description = obj.pm_description;
  requestData.is_payin_allowed = obj.is_payin_allowed;
  requestData.is_va_allowed = obj.is_va_allowed;
  requestData.is_contract_required = obj.is_contract_required;
  requestData.is_enabled_by_default = obj.is_enabled_by_default;
  requestData.is_partially_refundable = obj.is_partially_refundable;
  requestData.is_over_refundable = obj.is_over_refundable;
  requestData.is_cancellable = obj.is_cancellable;
  requestData.is_refundable = obj.is_refundable;
  requestData.is_expirable = obj.is_expirable;
  requestData.is_sender_validation_enabled = obj.is_sender_validation_enabled;
  requestData.is_activation_required = obj.is_activation_required;
  requestData.max_expiration_time_in_seconds = obj.max_expiration_time_in_seconds
    ? Number(obj.max_expiration_time_in_seconds)
    : null;
  requestData.default_expiry_time_seconds = obj.default_expiry_time
    ? Number(obj.default_expiry_time)
    : null;
  requestData.payin_method_display_name = obj.pm_display_name;
  requestData.start_time = new Date(obj.start_time).toLocaleTimeString();
  requestData.end_time = new Date(obj.end_time).toLocaleTimeString();
  requestData.excluded_day_list = obj.excluded_days_of_week;
  requestData.is_payin_method_available_24_7 =
    obj.is_payin_method_available_24_7;
  requestData.payin_method_additional_logo = obj.pm_additional_logo
    ? JSON.parse(obj.pm_additional_logo)
    : {};
  requestData.payin_method_config =
    obj.pm_config !== "" ? JSON.parse(obj.pm_config) : {};
  requestData.payin_method_param = obj.pm_param ? JSON.parse(obj.pm_param) : [];
  requestData.payin_method_instruction = obj.pm_instruction
    ? JSON.parse(obj.pm_instruction)
    : [];
  requestData.activation_config_param = obj.activation_config_param
    ? JSON.parse(obj.activation_config_param)
    : null;
  requestData.is_multiple_refund_allowed = obj.is_multiple_refund_allowed;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePayinMethod = async (action, payin_method_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/payin-method/${action}/${payin_method_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, {}, { headers: requestHeaders });
  return response.data;
};

export const getPayoutMethod = async (id) => {
  try {
    const url = `${WEB_GTWY_API_URL}/torc/payout-method/${id}`;
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(url, {
      headers: requestHeaders,
    });
    return response.data;
  } catch (error) {
    return errorMessage(error);
  }
};
export const getAllPayoutMethod = async (filterQueryArr = [], env) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-method`;
  if (filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (env) {
    requestHeaders["x-env"] = env;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllPayoutMethodForOrg = async (
  org_id,
  filterQueryArr = [],
  env
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-method/all`;
  if (!!filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (env) {
    requestHeaders["x-env"] = env;
  }
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const addPayoutMethod = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-method`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payout_method_name = obj.pm_name;
  requestData.payout_method_logo = obj.pm_logo;
  requestData.connector_id = obj.connector_id;
  requestData.country = obj.country;
  requestData.currency = obj.currency;
  requestData.sender_country = obj.sender_country;
  requestData.sender_currency = obj.sender_currency;
  requestData.beneficiary_country = obj.beneficiary_country;
  requestData.beneficiary_currency = obj.beneficiary_currency;
  requestData.payout_method_category = obj.category;
  requestData.payout_method_class = obj.pm_class;
  requestData.payout_method_group = obj.pm_group;
  requestData.min_amount = obj.min_amount;
  requestData.max_amount = obj.max_amount;
  requestData.payout_method_description = obj.pm_description;
  requestData.is_returnable = obj.is_returnable;
  requestData.is_contract_required = obj.is_contract_required;
  requestData.is_enabled_by_default = obj.is_enabled_by_default;
  requestData.payout_method_config = obj.pm_config
    ? JSON.parse(obj.pm_config)
    : {};
  requestData.payout_method_param = obj.pm_param
    ? JSON.parse(obj.pm_param)
    : [];
  requestData.payout_beneficiary_param = obj.p_b_param
    ? JSON.parse(obj.p_b_param)
    : [];
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editPayoutMethod = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-method/${obj.pm_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payout_method_name = obj.pm_name;
  requestData.payout_method_logo = obj.pm_logo;
  requestData.connector_id = obj.connector_id;
  requestData.country = obj.country;
  requestData.currency = obj.currency;
  requestData.sender_country = obj.sender_country;
  requestData.sender_currency = obj.sender_currency;
  requestData.beneficiary_country = obj.beneficiary_country;
  requestData.beneficiary_currency = obj.beneficiary_currency;
  requestData.payout_method_category = obj.category;
  requestData.payout_method_class = obj.pm_class;
  requestData.payout_method_group = obj.pm_group;
  requestData.min_amount = obj.min_amount;
  requestData.max_amount = obj.max_amount;
  requestData.payout_method_description = obj.pm_description;
  requestData.is_returnable = obj.is_returnable;
  requestData.is_enabled_by_default = obj.is_enabled_by_default;
  requestData.is_contract_required = obj.is_contract_required;
  requestData.payout_method_param = !!obj.pm_param
    ? JSON.parse(obj.pm_param)
    : [];
  requestData.payout_beneficiary_param = !!obj.p_b_param
    ? JSON.parse(obj.p_b_param)
    : [];
  requestData.payout_method_config = !!obj.pm_config
    ? JSON.parse(obj.pm_config)
    : {};

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePayoutMethod = async (action, payout_method_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/payout-method/${action}/${payout_method_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, {}, { headers: requestHeaders });
  return response.data;
};

export const fetchAllPaymentMethodSetting = async (
  filterQueryArr = [],
  org_id
) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-payment-method-setting/all`;
  if (filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllPaymentMethodSettingForOrg = async (
  filterQueryArr = [],
  org_id,
  env
) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-payment-method-setting`;
  if (filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  if (env) {
    requestHeaders["x-env"] = env;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getPMTSettingById = async (org_id, pm_id, environment) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-payment-method-setting/${pm_id}`;
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  requestHeaders["x-env"] = environment;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const updatePaymentMethodSetting = async (
  org_id,
  pm_id,
  status,
  environment,
  pm_type
) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-payment-method-setting/${pm_id}/${status}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = environment;
  const requestData = await createRequest();
  if (pm_type) {
    requestData.payment_method_type = pm_type;
  }
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const requestActivationForPMS = async (org_id, pm_id, env, pm_type) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-payment-method-setting/`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const requestData = await createRequest();
  if (env) {
    requestHeaders["x-env"] = env;
  }
  requestData.payment_method_id = pm_id;
  requestData.payment_method_type = pm_type;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllConnector = async (limit, page) => {
  try {
    let URL = `${WEB_GTWY_API_URL}/torc/connector`;
    if (limit && page) {
      URL = `${WEB_GTWY_API_URL}/torc/connector?limit=${limit}&page=${page}`;
    }
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const getAllConnectors = async (filterQueryArr = []) => {
  try {
    let url = `${WEB_GTWY_API_URL}/torc/connector`;
    if (filterQueryArr && filterQueryArr.length > 0) {
      const filterUrl = getFilterQueryUrl(filterQueryArr);
      if (filterUrl) {
        url = `${url}?${filterUrl}`;
      }
    }
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(url, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const addConnector = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/connector`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.connector_name = obj.connector_name;
  requestData.connector_config = JSON.parse(obj.connector_config);
  requestData.required_fields = obj.required_fields
    ? JSON.parse(obj.required_fields)
    : null;
  requestData.is_contract_required = obj.is_contract_required;
  requestData.category = obj.category;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editConnector = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/connector/${obj.connector_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.connector_name = obj.connector_name;
  requestData.connector_config = JSON.parse(obj.connector_config);
  requestData.required_fields = obj.required_fields
    ? JSON.parse(obj.required_fields)
    : null;
  requestData.is_contract_required = obj.is_contract_required;
  requestData.category = obj.category;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllOrgConnectorConfig = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/organization-connector-config/backoffice`;
  if (filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllOrgConnectorConfigByOrgID = async (
  org_id,
  filterQueryArr = []
) => {
  try {
    let URL = `${WEB_GTWY_API_URL}/torc/organization-connector-config`;
    if (filterQueryArr && filterQueryArr.length > 0) {
      const filterUrl = getFilterQueryUrl(filterQueryArr);
      if (filterUrl) {
        URL = `${URL}?${filterUrl}`;
      }
    }
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const getOrgConnectorConfig = async (orgConfigId, org_id) => {
  try {
    const URL = `${WEB_GTWY_API_URL}/torc/organization-connector-config/${orgConfigId}`;
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const addOrgConnectorConfig = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/organization-connector-config`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  const requestData = await createRequest();
  requestData.connector_id = obj.connector_id;
  requestData.org_connector_config = obj.config ? JSON.parse(obj.config) : {};
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editOrgConnectorConfig = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/organization-connector-config/${obj.org_connector_config_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  const requestData = await createRequest();
  requestData.connector_id = obj.connector_id;
  requestData.org_connector_config = obj.config ? JSON.parse(obj.config) : {};
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const rejectKyb = async ({
  kybId,
  note_to_customer,
  internal_note,
  rejection_field,
  resubmission_allowed,
  additional_documents,
}) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/kyb/${kybId}/reject`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.note_to_customer = note_to_customer;
  requestData.internal_note = internal_note;
  requestData.rejection_field = rejection_field;
  requestData.resubmission_allowed = resubmission_allowed;
  requestData.additional_documents = additional_documents;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const approveKyb = async ({
  kybId,
  note_to_customer,
  internal_note,
}) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/kyb/${kybId}/approve`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.note_to_customer = note_to_customer;
  requestData.internal_note = internal_note;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateKyb = async (org_id, id, end_point, updatedField) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/${end_point}/${id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const requestData = updatedField;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const isUrlValid = async (web_url) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization/is-unique/website-hostname/${web_url}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllDocs = async (filterArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-document`;
  if (filterArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadContract = async (org_id, data, type) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  if (type === "contract") {
    requestData.organization = {
      merchant_service_agreement_data: data,
    };
  }
  if (type === "support_document") {
    requestData.organization = {
      to_be_added_support_document_list: data,
    };
  }
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadDoc = async (file, values, org_id, type) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-document`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const requestData = await createRequest();
  requestData.file_name = file.name;
  requestData.file_type = file.type;
  requestData.descriptor = values.descriptor;
  requestData.is_signature_required = values.is_signature_required;
  requestData.is_signed = values.is_signed;
  requestData.is_email_notification_required =
    values.is_email_notification_required;
  requestData.to_be_notified_user_list = values.user_list;
  if (!!values.metadata) {
    requestData.metadata = values.metadata;
  }

  if (type === "support_document") {
    requestData.doc_type = "Support Document";
  }

  if (type === "reliance_agreement") {
    requestData.doc_type = "reliance_agreement";
  }

  if (type === "referral_agreement") {
    requestData.doc_type = "referral_agreement";
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });

  if (type === "contract") {
    const contract_id = response.data.data.org_document_id;
    let agreement_data;
    if (values.is_signed) {
      agreement_data = { signed: [contract_id], unsigned: [], merged: [] };
      uploadContract(org_id, agreement_data, type);
    } else {
      agreement_data = { signed: [], unsigned: [contract_id], merged: [] };
      uploadContract(org_id, agreement_data, type);
    }
  }

  if (type === "support_document") {
    let document_id_list = [];
    document_id_list.push(response.data.data.org_document_id);
    uploadContract(org_id, document_id_list, type);
  }

  const org_document_id = response.data.data.org_document_id;
  const uploadUrl = response.data.data.upload_url;
  delete axios.defaults.headers.common.Authorization;
  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": requestData.file_type,
    },
    redirect: "follow",
  });
  return { status: uploadResponse.status === 200, org_document_id };
};

export const uploadAttachment = async (file) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/backoffice-document/upload-url`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.file_name = file.name;
  requestData.file_type = file.type;

  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });

  const uploadUrl = response.data.data.url;
  delete axios.defaults.headers.common.Authorization;

  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": requestData.file_type,
    },
    redirect: "follow",
  });

  return {
    status: uploadResponse.status === 200,
    data: {
      document_path: response.data.data.document_path,
      file_type: file.type,
      bucket: response.data.data.bucket,
    },
  };
};

export const getSingleDoc = async (org_id, org_doc_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-document/${org_doc_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const removeOrgDocument = async (org_id, org_doc_id, doc_type = "") => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-document/${org_doc_id}/remove`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.patch(
    url,
    { doc_type },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const loginUser = async ({ org_id, org_user_id }) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-user/bo-admin-login`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.org_id = org_id;
  requestData.org_user_id = org_user_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrgUserPeer = async (org_user_id, userData, org_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-user/${org_user_id}`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  let requestData = await createRequest();
  requestData.is_admin = userData.is_admin;
  requestData.is_developer = userData.is_developer;
  if (userData.hasOwnProperty("is_active")) {
    requestData.is_active = userData.is_active;
  }
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-org-user-id"] = org_user_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendLoginOtp = async (auth_id, session_id) => {
  const url = `${WEB_GTWY_API_URL}/resend-login-otp`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.auth_id = auth_id;
  requestData.session_id = session_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const setTotpSecret = async (userData, with_log = true) => {
  const url = `${WEB_GTWY_API_URL}/set-totp-secret`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateTotpStatus = async (userData, with_log = true) => {
  const url = `${WEB_GTWY_API_URL}/update-totp-status`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const sendOtpOnPhoneEmail = async (requestObj) => {
  const url = `${WEB_GTWY_API_URL}/sendOtpOnPhoneEmail`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  requestData.auth_id = requestObj.auth_id;
  requestData.session_id = requestObj.session_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  console.log(response);
  return response.data;
};

export const verifyEmailToken = async (email_token) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user/verify-email/${email_token}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendVerifyEmail = async (email) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user/resend-verification-email/${email}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const inviteOrgUser = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-user/invite-organization-user`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = obj.email;
  requestData.first_name = obj.first_name;
  requestData.last_name = obj.last_name;
  requestData.org_name = obj.org_name;
  requestData.org_country = obj.org_country;
  requestData.password = obj.password;
  requestData.pricing_plan_id = obj.plan_id;
  requestData.gca_pricing_plan_id = obj.gca_plan_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const resendInvitation = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-user/resend-invitation-email/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllAccounts = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice-user/peers`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateBoUserPeer = async (bo_user_id, userData) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-user/${bo_user_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData.is_admin = userData.is_admin;
  requestData.user_type = userData.user_type;
  requestData.is_active = userData.is_active;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchOrgPricing = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing/organization`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPricingByCountryAndCurrency = async () => {
  const url = `${WEB_GTWY_API_URL}/torc/payin-method?country=AU&currency=AUD`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPayoutByCountryAndCurrency = async () => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-method?sender_country=AU&sender_currency=AUD&beneficiary_country=AU&beneficiary_currency=AUD`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPayoutMethod = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-method`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getPayoutBeneficiaryByID = async (beneficiary_id, org_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary/${beneficiary_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const updatePayoutBeneficiary = async (
  beneficiary_id,
  beneficiaryData,
  org_id
) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary/backoffice/${beneficiary_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  let requestData = await createRequest();
  requestData = { ...beneficiaryData, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPricingByPaymentMethodId = async (
  org_id,
  payin_method_id,
  pm_class,
  pm_group
) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing/organization/payin-method/${payin_method_id}/payin-method-class/${pm_class}/payin-method-group/${pm_group}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPricingByPayoutMethodId = async (
  org_id,
  payout_method_id,
  pm_class,
  pm_group
) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing/organization/payout-method/${payout_method_id}/payout-method-class/${pm_class}/payout-method-group/${pm_group}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPlanPricing = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing/plan/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPlanPricingByPaymentMethodId = async (
  plan_id,
  payin_method_id
) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing/plan/${plan_id}/payin-method/${payin_method_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPlanPricingByPayoutMethodId = async (
  plan_id,
  payin_method_id
) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing/plan/${plan_id}/payout-method/${payin_method_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllPlans = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/plan`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getPlan = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/plan/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const addPricingPlan = async (plan_name, scope) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/plan`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_name = plan_name;
  requestData.scope = scope;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editPricingPlan = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/plan/${obj.plan_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_name = obj.plan_name;
  requestData.scope = obj.scope;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPlanAssocs = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/organization-plan`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getPlanAssoc = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/organization-plan/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const addPlanAssoc = async (obj) => {
  const endpoint = obj.org_id !== "" ? "organization-plan" : "partner-plan";
  const url = `${WEB_GTWY_API_URL}/pricing-engine/${endpoint}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_id = obj.plan_id;
  {
    endpoint === "organization-plan"
      ? (requestData.org_id = obj.org_id !== "" ? obj.org_id : null)
      : (requestData.partner_id =
          obj.partner_id !== "" ? obj.partner_id : null);
  }
  requestData.scope = obj.scope || "DOMESTIC";
  requestData.start_at = parseInt(obj.start_at);
  requestData.end_at = parseInt(obj.end_at);
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editPlanAssoc = async (obj) => {
  const endpoint =
    obj.org_plan_id !== ""
      ? `organization-plan/${obj.org_plan_id}`
      : `partner-plan/${obj.partner_plan_id}`;
  const url = `${WEB_GTWY_API_URL}/pricing-engine/${endpoint}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_id = obj.plan_id;
  {
    obj.org_plan_id !== ""
      ? (requestData.org_id = obj.org_id)
      : (requestData.partner_id = obj.partner_id);
  }
  requestData.scope = obj.scope || "DOMESTIC";
  requestData.start_at = parseInt(obj.start_at);
  requestData.end_at = parseInt(obj.end_at);
  requestData.plan_level = obj.plan_level || null;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllCountryPlans = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/country-plan`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const addCountryPlan = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/country-plan`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_id = obj.plan_id;
  requestData.country = obj.country;
  requestData.start_at = parseInt(obj.start_at);
  requestData.end_at = parseInt(obj.end_at);
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editCountryPlan = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/country-plan/${obj.cp_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_id = obj.plan_id;
  requestData.country = obj.country;
  requestData.start_at = parseInt(obj.start_at);
  requestData.end_at = parseInt(obj.end_at);
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getCountryPlan = async (cp_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/country-plan/${cp_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllCountryTax = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/country-tax`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const addCountryTax = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/country-tax`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.country = obj.country;
  requestData.tax_percentage = obj.tax_per;
  requestData.tax_breakup = obj.tax_breakup;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editCountryTax = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/country-tax/${obj.ct_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.country = obj.country;
  requestData.tax_percentage = obj.tax_per;
  requestData.tax_breakup = obj.tax_breakup;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getCountryTax = async (ct_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/country-tax/${ct_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllOrganizationTax = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/organization-tax`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addOrganizationTax = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/organization-tax`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.country = obj.country;
  requestData.org_id = obj.org_id;
  requestData.tax_calculation_mode = obj.tax_calculation_mode;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrganizationTax = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/organization-tax/${obj.org_tax_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.country = obj.country;
  requestData.org_id = obj.org_id;
  requestData.tax_calculation_mode = obj.tax_calculation_mode;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSpecificOrganizationTax = async (org_tax_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/organization-tax/${org_tax_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllMonthlyMin = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/monthly-minimum`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchOrgSpecificMonthlyMin = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/monthly-minimum/organization-monthly-minimum`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getMonthlyMin = async (mm_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/monthly-minimum/${mm_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addMonthlyMin = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/monthly-minimum`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope ? obj.scope.toUpperCase() : null;
  requestData.plan_id = obj.plan_id !== "" ? obj.plan_id : null;
  requestData.org_id = obj.org_id !== "" ? obj.org_id : null;
  requestData.partner_id = obj.partner_id !== "" ? obj.partner_id : null;
  requestData.fees_currency = obj.fees_currency;
  requestData.fees_amount = obj.fees_amount;
  requestData.valid_from = obj.valid_from;
  requestData.effective_from_date = obj.effective_from_date;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editMonthlyMin = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/monthly-minimum/${obj.mm_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.plan_id = obj.plan_id !== "" ? obj.plan_id : null;
  requestData.partner_id = obj.partner_id !== "" ? obj.partner_id : null;
  requestData.fees_amount = obj.fees_amount;
  requestData.valid_from = obj.valid_from;
  requestData.fees_currency = obj.fees_currency;
  requestData.effective_from_date = obj.effective_from_date;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteMonthlyMin = async (mm_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/monthly-minimum/${mm_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

// recurring fee

export const fetchAllRecurringFee = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/recurring-fees`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchOrgSpecificRecurringFee = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/recurring-fee/organization-recurring-fees`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getRecurringFee = async (recurring_fee_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/recurring-fees/${recurring_fee_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addRecurringFee = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/recurring-fees`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope ? obj.scope.toUpperCase() : null;
  requestData.plan_id = obj.plan_id !== "" ? obj.plan_id : null;
  requestData.org_id = obj.org_id !== "" ? obj.org_id : null;
  requestData.partner_id = obj.partner_id !== "" ? obj.partner_id : null;
  requestData.fees_currency = obj.fees_currency;
  requestData.fees_amount = obj.fees_amount;
  requestData.start_date = obj.start_date;
  requestData.end_date = obj.end_date;
  requestData.fees_name = obj.fees_name;
  requestData.payin_method_id = obj.payin_method_id;
  requestData.is_pro_rated = obj.is_pro_rated;
  requestData.fees_type = obj.fees_type;
  requestData.fees_description = obj.fees_description;
  requestData.billing_frequency = obj.billing_frequency;
  requestData.is_part_of_mm = obj.is_part_of_mm;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editRecurringFee = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/recurring-fees/${obj.recurring_fees_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope ? obj.scope.toUpperCase() : null;
  requestData.plan_id = obj.plan_id !== "" ? obj.plan_id : null;
  requestData.org_id = obj.org_id !== "" ? obj.org_id : null;
  requestData.partner_id = obj.partner_id !== "" ? obj.partner_id : null;
  requestData.fees_currency = obj.fees_currency;
  requestData.fees_amount = obj.fees_amount;
  requestData.start_date = obj.start_date;
  requestData.end_date = obj.end_date;
  requestData.fees_name = obj.fees_name;
  requestData.payin_method_id = obj.payin_method_id;
  requestData.is_pro_rated = obj.is_pro_rated;
  requestData.fee_type = obj.fee_type;
  requestData.fee_description = obj.fee_description;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteRecurringFee = async (recurring_fee_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/recurring-fees/${recurring_fee_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayinMethodPricing = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-pricing`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addPayinMethodPricing = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-pricing`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope;
  requestData.plan_id = obj.plan_id !== "" ? obj.plan_id : null;
  requestData.org_id = obj.org_id !== "" ? obj.org_id : null;
  requestData.partner_id = obj.partner_id !== "" ? obj.partner_id : null;
  requestData.payin_method_class =
    obj.payin_method_class !== "" ? obj.payin_method_class : null;
  requestData.payin_method_group =
    obj.payin_method_group !== "" ? obj.payin_method_group : null;
  requestData.connector_id = obj.connector_id;
  requestData.payin_method_id = obj.pm_id;
  requestData.fx_markup_percentage = obj.fxmp;
  requestData.fees_currency = obj.fees_currency;
  requestData.event_name = obj.event;
  requestData.is_tiered_pricing = obj.is_tiered_pricing;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editPayinMethodPricing = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-pricing/${obj.pmp_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope;
  requestData.plan_id = obj.plan_id;
  requestData.org_id = obj.org_id;
  requestData.partner_id = obj.partner_id;
  requestData.payin_method_class = obj.payin_method_class;
  requestData.payin_method_group = obj.payin_method_group;
  requestData.connector_id = obj.connector_id;
  requestData.payin_method_id = obj.pm_id;
  requestData.fx_markup_percentage = obj.fxmp;
  requestData.fees_currency = obj.fees_currency;
  requestData.event_name = obj.event;
  requestData.is_tiered_pricing = obj.is_tiered_pricing;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getApproveInvestAccountConfig = async (filter_arr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/bank-interface`;
  if (filter_arr.length > 0) {
    const filterUrl = getFilterQueryUrl(filter_arr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPayinMethodPricing = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-pricing/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const deletePayinMethodPricing = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-pricing/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayoutMethodPricing = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-pricing`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const addPayoutMethodPricing = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-pricing`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope;
  requestData.plan_id = obj.plan_id !== "" ? obj.plan_id : null;
  requestData.org_id = obj.org_id !== "" ? obj.org_id : null;
  requestData.partner_id = obj.partner_id !== "" ? obj.partner_id : null;
  requestData.connector_id = obj.connector_id;
  requestData.payout_method_id = obj.pom_id !== "" ? obj.pom_id : null;
  requestData.payout_method_class = obj.payout_method_class;
  requestData.payout_method_group = obj.payout_method_group;
  requestData.fx_markup_percentage = obj.fxmp;
  requestData.fees_currency = obj.fees_currency;
  requestData.event_name = obj.event;
  requestData.is_tiered_pricing = obj.is_tiered_pricing;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editPayoutMethodPricing = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-pricing/${obj.pomp_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.scope = obj.scope;
  requestData.plan_id = obj.plan_id;
  requestData.org_id = obj.org_id;
  requestData.partner_id = obj.partner_id;
  requestData.connector_id = obj.connector_id;
  requestData.payout_method_class = obj.payout_method_class;
  requestData.payout_method_group = obj.payout_method_group;
  requestData.payout_method_id = obj.pom_id;
  requestData.fx_markup_percentage = obj.fxmp;
  requestData.fees_currency = obj.fees_currency;
  requestData.event_name = obj.event;
  requestData.is_tiered_pricing = obj.is_tiered_pricing;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const deletePayoutMethodPricing = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-pricing/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getPayoutMethodPricing = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-pricing/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllPmPricingTier = async (pmp_or_pomp_id) => {
  try {
    const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-tier?related_entity_id=${pmp_or_pomp_id}`;
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(url, {
      headers: requestHeaders,
    });
    return response.data;
  } catch (error) {
    return errorMessage(error);
  }
};
export const getPmpTier = async (tier_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-tier/${tier_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const addPmpTier = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-tier`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.pmp_or_pomp_id = obj.pmp_id ? obj.pmp_id : obj.pomp_id;
  requestData.tier_name = obj.tier_name !== "" ? obj.tier_name : null;
  requestData.type = obj.type !== "" ? obj.type : null;
  requestData.tier_start = obj.tier_start !== "" ? +obj.tier_start : null;
  requestData.tier_end = obj.tier_end !== "" ? +obj.tier_end : null;
  requestData.percentage_fees = +obj.per_fees;
  requestData.static_fees = +obj.st_fees;
  requestData.min_fees = obj.min_fees === "" ? null : +obj.min_fees;
  requestData.max_fees = obj.max_fees === "" ? null : +obj.max_fees;
  requestData.static_fees_currency = obj.sf_currency;
  requestData.is_default = obj.is_default;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const editPmpTier = async (tier) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-tier/${tier.tier_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.pmp_or_pomp_id = tier.pmp_id ? tier.pmp_id : tier.pomp_id;
  requestData.tier_name = tier.tier_name || null;
  requestData.type = tier.type || null;
  requestData.tier_start = tier.tier_start || null;
  requestData.tier_end = tier.tier_end || null;
  requestData.percentage_fees = tier.per_fees;
  requestData.static_fees = tier.st_fees;
  requestData.min_fees = tier.min_fees === "" ? null : tier.min_fees;
  requestData.max_fees = tier.max_fees === "" ? null : tier.max_fees;
  requestData.static_fees_currency = tier.sf_currency;
  requestData.is_default = tier.is_default;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deletePmpTier = async (tier_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-tier/${tier_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createPricingSetup = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.pricing_setup_name = obj.pricing_setup_name;
  requestData.reference_id = obj.reference_id;
  requestData.scope = obj.scope;
  requestData.is_applied = obj.is_applied;
  requestData.pricing_config = obj.pricing_config;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePricingSetup = async (ps_id, obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup/${ps_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.pricing_config = obj.pricing_config;
  requestData.updated_tier_object = obj.updated_tier_object || null;
  requestData.is_applied = obj.is_applied;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPricingSetup = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const applyPricingToPlanOrOrg = async (
  pricing_setup_id,
  pricing_config
) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup/apply-pricing/${pricing_setup_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.pricing_config = pricing_config;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPricingSetupById = async (pricing_setup_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup/${pricing_setup_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deletePricingSetup = async (pricing_setup_id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/pricing-setup/${pricing_setup_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllRecons = async (limit, page) => {
  let url = `${WEB_GTWY_API_URL}/torc/reconciliation/batch`;
  if (limit && page) {
    url = `${WEB_GTWY_API_URL}/torc/reconciliation/batch?limit=${limit}&page=${page}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getReconBatchById = async (recon_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/reconciliation/batch/${recon_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBatchByBatchId = async (job_id, batch_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${job_id}/batch/${batch_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllReconTrxn = async (batch_id, limit, page) => {
  let url = `${WEB_GTWY_API_URL}/torc/reconciliation?reconciliation_batch_id=${batch_id}`;
  if (limit && page) {
    url = `${WEB_GTWY_API_URL}/torc/reconciliation?reconciliation_batch_id=${batch_id}&limit=${limit}&page=${page}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllSettlements = async (filterQueryArr = [], org_id, env) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (!!env) requestHeaders["x-env"] = env;
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlement = async (settlement_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/${settlement_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const releaseAllSettlementPayout = async (
  org_id,
  env,
  currency,
  date
) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/release-settlement-payout`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const requestData = await createRequest();
  requestData["org_id"] = org_id;
  requestData["currency"] = currency;
  requestData["settlement_payout_till_date"] = date
    ? date.toISOString()
    : new Date(Date.now()).toISOString();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const ReleaseSettlementPayout = async (org_id, settlement_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/release-settlement-payout`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.post(
    url,
    { settlement_id },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const getAllSettlementTrxn = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-transaction/backoffice`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchWalletTransactionById = async (transaction_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-transaction/${transaction_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const settlementEnabled = async (org_id, enabled) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = { is_settlement_allowed: enabled };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateSettlementType = async (org_id, type) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = { settlement_type: type };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateSettleToPayoutWallet = async (org_id, val) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = { is_settle_to_payout_wallet: val };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const runSettlementForOrg = async (org_id, env, currency, date) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/settle/org/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const requestData = await createRequest();
  requestData["currency"] = currency;
  requestData["settlement_calculate_till_date"] = date
    ? date.toISOString()
    : new Date(Date.now()).toISOString();
  requestData["org_id"] = org_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementPayout = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout/backoffice`;
  const requestHeaders = await getRequestHeaders();

  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
    filterQueryArr = [
      ...filterQueryArr,
      { filter_key: "org_id", filter_value: org_id },
    ];
  }

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementPayoutDetails = async () => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout/settlement-summary`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchSettlePayoutById = async (payoutId, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout/${payoutId}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllTransactions = async (
  filterQueryArr = [],
  org_id,
  env
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (!!env) requestHeaders["x-env"] = env;
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchTransactionByID = async (transaction_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction/${transaction_id}`;
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllOrgSettlementConfig = async (
  filterQueryArr = [],
  org_id
) => {
  let URL = `${WEB_GTWY_API_URL}/settlement-organization-config`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      URL = `${URL}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const createOrgSettlementConfig = async (data) => {
  const {
    org_id,
    currency,
    settlement_payout_mode,
    min_amount_threshold,
    org_settlement_bank_id,
    is_settlement_enabled,
  } = data;
  try {
    const URL = `${WEB_GTWY_API_URL}/settlement-organization-config`;
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const requesData = await createRequest();
    requesData.currency = currency;
    requesData.settlement_payout_mode = settlement_payout_mode;
    requesData.min_amount_threshold = min_amount_threshold;
    requesData.is_settlement_enabled = is_settlement_enabled;
    if (settlement_payout_mode === "SETTLEMENT_BANK") {
      requesData.org_settlement_bank_id = org_settlement_bank_id;
    }
    const response = await axios.post(URL, requesData, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

// export const updateOrgSettlementConfig = async (org_id, soc_id, data) => {
//   try {
//     const URL = `${WEB_GTWY_API_URL}/settlement-organization-config/${soc_id}`;
//     const requestHeaders = await getRequestHeaders();
//     requestHeaders["x-org-id"] = org_id;
//     let requesData = await createRequest();
//     requesData = { ...requesData, ...data };
//     const response = await axios.patch(URL, requesData, {
//       headers: requestHeaders,
//     });
//     const res = response.data;
//     return res;
//   } catch (error) {
//     return errorMessage(error);
//   }
// };

export const getSettlementConfigById = async (org_id, soc_id) => {
  try {
    const URL = `${WEB_GTWY_API_URL}/settlement-organization-config/${soc_id}`;
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(URL, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getOrgSettlementConfig = async (soc_id, org_id) => {
  try {
    const URL = `${WEB_GTWY_API_URL}/settlement-organization-config/${soc_id}`;
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(URL, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getOrgSettlementBanks = async (org_id, currency) => {
  try {
    const url = `${WEB_GTWY_API_URL}/backoffice/organization-settlement-bank`;
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(url, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const updateWalletAccount = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet/${obj.wallet_id}/currency/${obj.currency}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  const requestData = await createRequest();
  requestData.is_settlement_allowed = obj.is_sa;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createInvoice = async (org_id, body) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice`;
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  requestHeaders["x-env"] = "production";
  const response = await axios.post(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllInvoices = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/backoffice`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getInvoiceById = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getInvoiceStatistics = async () => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/invoice-statistics`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateInvoicingStatus = async (obj) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/${obj.invoice_id}/status/backoffice`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  requestData.amount = obj.amount;
  requestData.fees_currency = obj.fees_currency;
  requestData.invoice_status = obj.invoice_status;
  requestData.event_id = obj.event_id;
  requestData.payment_mode = obj.payment_mode;
  requestData.collected_amount = obj.collected_amount;
  requestData.transaction_reference_id = obj.transaction_reference_id
    ? obj.transaction_reference_id
    : null;
  requestData.additional_data = obj.additional_data
    ? obj.additional_data
    : null;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const markInvoiceAsCancelled = async (org_id, invoice_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/cancel`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const markInvoiceShipped = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/ship`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const markInvoiceUnshipped = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/unship`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const markInvoiceDeleted = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const regenerateInvoicePdf = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/regenerate-pdf`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const sendPaymentReminderEmail = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/payment-reminder`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const raiseInvoice = async (obj, org_id, filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/generate-from-config`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  requestData.start_date = obj.start_date;
  requestData.end_date = obj.end_date;
  requestData.invoice_date = obj.invoice_date;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const autoDeductAndMarkInvoicePaid = async (org_id, invoice_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/auto-deduct-and-pay`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const requestData = await createRequest();
  requestData.org_id = org_id;
  requestData.invoice_id = invoice_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getInvoiceAtGlance = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/overview`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const migrateGca = async (obj, org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}/gca/migrate`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, obj, {
    headers: requestHeaders,
  });
  return response.data;
};
export const initiateGcaActivation = async (org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}/gca/initiate-activation`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const completeGcaActivation = async (
  org_id,
  gcaData,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}/gca/complete-activation`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.patch(url, gcaData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateGCAEnableDisable = async (org_id, status) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}/gca/${status}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateCrossBorderSettlement = async (org_id, status) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}/cross-border-settlement/${status}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgSettlementBank = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-settlement-bank`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const getSettlementBankById = async (osb_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-settlement-bank/${osb_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateSettlementBank = async (org_id, action, osb_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-settlement-bank/${org_id}/${action}/${osb_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, {}, { headers: requestHeaders });
  return response.data;
};
export const updateSettlementBankById = async (osb_id, data, org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-settlement-bank/${osb_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const requestData = { ...data };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrganization = async (org_id, data) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  // const requestData = await createRequest();
  // requestData = { ...data };
  const response = await axios.patch(url, data, {
    headers: requestHeaders,
  });
  return response.data;
};

export const findAllOrgControl = async (filterQuerryArr = [], env = "all") => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-control/all`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrgControl = async (org_id, env, key_type, key, value) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-control`;
  const requestData = await createRequest();
  requestData.key_type = key_type;
  requestData.key = key;
  requestData.value = value;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getOrgControl = async (filterQuerryArr = [], org_id, env) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-control`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getOrgControlByKey = async (org_id, env, key_type, key) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-control`;
  if (key_type && key) {
    url = `${url}/${key_type}/${key}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPayinAnalytics = async (filterQueryArr = [], org_id, env) => {
  let url = `${WEB_GTWY_API_URL}/torc/analytics`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  if (org_id && org_id !== "all") requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAnalyticsCurrencyList = async (
  filterArr = [],
  org_id,
  env
) => {
  let url = `${WEB_GTWY_API_URL}/torc/analytics/currency-list`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  if (org_id && org_id !== "all") requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchTPV = async (filterQueryArr = [], org_id = "") => {
  let url = `${WEB_GTWY_API_URL}/torc/analytics/tpv`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBasicFxRates = async (currency_pair_list) => {
  let url = `${WEB_GTWY_API_URL}/torc/list-fx-rates`;
  const requestData = await createRequest();
  requestData.currency_pair_list = currency_pair_list;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllTemplates = async () => {
  const url = `${WEB_GTWY_API_URL}/get-email-template-info`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const sendBroadcastEmail = async (obj) => {
  let url = `${WEB_GTWY_API_URL}/broadcast-email`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email_list = obj.email_list.length > 0 ? obj.email_list : [];
  requestData.org_list = obj.org_list.length > 0 ? obj.org_list : [];
  requestData.user_type = obj.user_type || "ALL";
  requestData.sender_email_address = obj.sender_email_address;
  requestData.email_subject = obj.email_subject;
  requestData.email_template_name = obj.email_template_name;
  requestData.html_body = obj.html_body || null;
  requestData.custom_params = obj.custom_params || null;
  requestData.file_locations = obj.file_locations || [];
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchOrgUsingConnectorID = async (connector_id, startDate) => {
  const url = `${WEB_GTWY_API_URL}/broadcast-email/fetch-organization-using-connector-id/${connector_id}/?date=${startDate}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getBOSetting = async (env, key, bo_user_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-setting/${env}/${key}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-bo-user-id"] = bo_user_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createBOSetting = async (data, bo_user_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice-setting`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-bo-user-id"] = bo_user_id;
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };
  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllJobRepos = async (filterArr = []) => {
  let url = `${WEB_GTWY_API_URL}/scheduler-repository`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const createSchedulerRepo = async (obj) => {
  let url = `${WEB_GTWY_API_URL}/scheduler-repository`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.name = obj.job_name;
  requestData.job_scheduling_policy = {
    cron_expression: obj.cron_expression || null,
    start_date: obj.start_date || null,
    end_date: obj.end_date || null,
    every_in_sec: obj.every_in_sec || null,
    limit: +obj.limit || null,
  };
  requestData.job_processor_info = {
    url: obj.job_processor_url || null,
    header: obj.job_processor_header
      ? JSON.parse(obj.job_processor_header)
      : null,
    body: obj.job_processor_body ? JSON.parse(obj.job_processor_body) : null,
    method: obj.job_processor_method || null,
  };
  requestData.call_back_info = {
    url: obj.call_back_url || null,
    header: obj.call_back_header ? JSON.parse(obj.call_back_header) : null,
    method: obj.call_back_method || null,
  };
  requestData.tag_list =
    obj.tag_list.length > 0 ? JSON.parse(obj.tag_list) : null;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const deleteAllJobRepos = async (id) => {
  let url = `${WEB_GTWY_API_URL}/scheduler-repository/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, { headers: requestHeaders });
  return response.data;
};

export const enableJobRepos = async (id) => {
  let url = `${WEB_GTWY_API_URL}/scheduler-repository/${id}/enable`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, {}, { headers: requestHeaders });
  return response.data;
};

export const disableJobRepos = async (id) => {
  let url = `${WEB_GTWY_API_URL}/scheduler-repository/${id}/disable`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, {}, { headers: requestHeaders });
  return response.data;
};

export const fetchAllPayinConfig = async (filterArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/organization-payin-method-config/backoffice`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    url = `${WEB_GTWY_API_URL}/torc/organization-payin-method-config`;
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const createOrgPayinConfig = async (obj) => {
  let url = `${WEB_GTWY_API_URL}/torc/organization-payin-method-config`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  const requestData = await createRequest();
  requestData.payin_method_id = obj.payin_method_id;
  requestData.org_payin_method_config = obj.config
    ? JSON.parse(obj.config)
    : {};
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateOrgPayinConfig = async (obj) => {
  let url = `${WEB_GTWY_API_URL}/torc/organization-payin-method-config/${obj.org_payin_method_config_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = obj.org_id;
  const requestData = await createRequest();
  requestData.payin_method_id = obj.payin_method_id;
  requestData.org_payin_method_config = obj.config
    ? JSON.parse(obj.config)
    : {};
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getOrgPmConfig = async (orgConfigId, org_id) => {
  const URL = `${WEB_GTWY_API_URL}/torc/organization-payin-method-config/${orgConfigId}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const getCustomerWallets = async (
  filterQueryArr = [],
  org_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet?scope=CUSTOMER`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}&${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getWalletById = async (wallet_id, org_id, env = "production") => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet/${wallet_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchWalletMaster = async (org_id, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet?scope=MASTER`;

  const requestHeaders = await getRequestHeaders();

  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createWalletFundTransfer = async (
  payload,
  org_id,
  env = "production"
) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/wallet-fund-transfer/create`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  let requestData = await createRequest();
  requestData = {
    debit_wallet_id: payload.debit_wallet_id,
    credit_wallet_id: payload.credit_wallet_id,
    amount: +payload.amount,
    currency: payload.currency,
    organization_reference_id: payload?.organization_reference_id || null,
    description: payload?.description || null,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSubDomains = async (host) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/subdomains?host=${host}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getDomainOwnerInfo = async (host) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/whois?host=${host}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getDNSRecords = async (host) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/dnsrecords?host=${host}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllInboundFundNotice = async (
  filterQueryArr = [],
  org_id
) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/torc/inbound-fund-notice/all`;
  if (filterQueryArr && filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getAllPayout = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPayoutById = async (payoutId, org_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout/${payoutId}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllBalances = async () => {
  const url = `${WEB_GTWY_API_URL}/torc/balance?detailed=true`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getIFNById = async (org_id, ifn_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/torc/inbound-fund-notice/${ifn_id}`;
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPartnerAuditTrail = async (
  filterQueryArr = [],
  partner_org_id
) => {
  let url = `${WEB_GTWY_API_URL}/partner-audit-trail`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = partner_org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getPartnerAuditTrailById = async (partner_org_id, audit_id) => {
  const url = `${WEB_GTWY_API_URL}/partner-audit-trail/${audit_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = partner_org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllAuditTrail = async (filterQueryArr = [], org_id) => {
  let url = `${WEB_GTWY_API_URL}/audit-trail`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAuditTrailById = async (org_id, audit_id) => {
  const url = `${WEB_GTWY_API_URL}/audit-trail/${audit_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllBOAuditTrail = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/bo-audit-trail`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getBOAuditTrailById = async (audit_id) => {
  const url = `${WEB_GTWY_API_URL}/bo-audit-trail/${audit_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = "production";
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateIFN = async (org_id, ifn_id, requestData) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/torc/inbound-fund-notice/${ifn_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateCustomer = async (customer_id, customerData, org_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customer_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  requestData = { ...customerData, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getCustomerById = async (customer_id, org_id, env) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customer_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  if (!!env) {
    requestHeaders["x-env"] = env;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const completeInboundFund = async (ifn_id, org_id, data) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/torc/inbound-fund-notice/${ifn_id}/complete`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  let requestData = await createRequest();
  requestData = { ...data, ...requestData };

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllReconciliationJobs = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getReconciliationJobById = async (job_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${job_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getReconciliationBatchByJobId = async (
  job_id,
  filterQueryArr = []
) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${job_id}/batch`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllReconsByBatchId = async (batch_id, filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/batch/${batch_id}/reconciliation`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getReconReport = async (batch_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/internal/job/batch/${batch_id}/report`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getTreasuryBalanceHistory = async (
  connector_name,
  start_time,
  end_time
) => {
  let url = `${WEB_GTWY_API_URL}/recon/balance?connector_name=${connector_name}&start_time=${start_time}&end_time=${end_time}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getConnectorBalance = async (connector_name) => {
  let url = `${WEB_GTWY_API_URL}/connector/${connector_name}/balance`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createNewReconJob = async (data) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.connector_name = data.connector_name;
  requestData.connector_id = data.connector_id;
  requestData.entity = data.entity;
  requestData.payment_method_name = data.payment_method_name || null;
  requestData.start_at = getISOformatString(data.start_at);
  requestData.is_to_verify_connector_settlement =
    data.is_to_verify_connector_settlement;
  requestData.end_at =
    data.end_at === null ? null : getISOformatString(data.end_at);
  requestData.is_scheduled = data.is_scheduled;
  requestData.is_report_needed = data.is_report_needed;
  requestData.frequency = data.frequency;
  requestData.job_name = data.job_name || null;
  requestData.post_reconciliation_callback =
    data.callback_url === "" ? null : { url: data.callback_url };

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateReconJob = async (data) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${data.reconciliation_job_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.frequency = data.frequency;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const disableReconJob = async (reconciliation_job_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${reconciliation_job_id}/disable`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const enableReconJob = async (reconciliation_job_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${reconciliation_job_id}/enable`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createNewReconJobBatch = async (
  recon_job_id,
  batch_raw_report_id_list,
  report_duration_days,
  report_duration_hours
) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/${recon_job_id}/batch`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  if (batch_raw_report_id_list) {
    requestData["raw_report_id_list"] = batch_raw_report_id_list;
    requestData["report_duration_seconds"] =
      moment.duration(report_duration_days, "days").asSeconds() +
      moment.duration(report_duration_hours, "hours").asSeconds();
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const retriggerReconJobBatch = async (
  job_id,
  batch_id,
  report_doc_id
) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/internal/job/${job_id}/batch/${batch_id}/retrigger`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  if (report_doc_id) {
    requestData["raw_report_id_list"] = report_doc_id;
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadReconReport = async (report_req, report_file) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/reconciliation-job/batch/report`;
  const requestHeaders = await getRequestHeaders();

  const response = await axios.post(url, report_req, {
    headers: requestHeaders,
  });
  const uploadUrl = response.data.data.upload_url;
  const report_doc_id = response.data.data.report_doc_id;
  delete axios.defaults.headers.common.Authorization;
  const uploadResponse = await axios.put(uploadUrl, report_file, {
    headers: {
      "Content-Type": report_req.file_type,
    },
    redirect: "follow",
  });
  return {
    success: uploadResponse.status === 200,
    report_doc_id,
  };
};

export const updateDeVirtualAccount = async (org_id, env, value) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/de-virtual-account/activate`;
  const requestData = await createRequest();
  requestData.is_bc_local_de_aud_allowed = value;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  requestHeaders["x-env"] = env;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

//Transaction Monitoring Routes

export const addNewTransactionRule = async (data, env = "production") => {
  const URL = `${WEB_GTWY_API_URL}/torc/rule`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  let requesData = await createRequest();
  requesData = { ...data };
  const response = await axios.post(URL, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editTransactionRule = async (
  data,
  rule_id,
  env = "production"
) => {
  const URL = `${WEB_GTWY_API_URL}/torc/rule/${rule_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  let requesData = await createRequest();
  requesData = { ...data };
  const response = await axios.patch(URL, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchRuleParams = async (data, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-rule-param/params`;
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchRuleAnalytics = async (env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/rule/analytics`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllTransactionRules = async (
  filterQueryArr = [],
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/rule`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const deleteTransactionRule = async (rule_id, env = "production") => {
  const URL = `${WEB_GTWY_API_URL}/torc/rule/${rule_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.delete(URL, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchTransactionRuleByID = async (rule_id, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/torc/rule/${rule_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllFlaggedTransactions = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-control`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addKeyConnectorMap = async (obj, env = "production") => {
  const url = `${WEB_GTWY_API_URL}/torc/key-connector-map`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.org_id = obj.org_id;
  requestData.connector_id = obj.connector_id;
  requestData.weightage = +obj.weightage;
  requestData.key =
    obj.custom_key.length > 0
      ? obj.custom_key
      : `${obj.entity}_CREATE_${obj.entity_method_name}`;
  requestHeaders["x-env"] = env;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const updateFlaggedTransaction = async (
  transaction_alert_id,
  status
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-control/${transaction_alert_id}/${status}`;
  const requestHeaders = await getRequestHeaders();
  let requesData = await createRequest();
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllFlaggedTransactionsByRule = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-control/rule`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

//Organization Screening Routes
export const getOrganizationScreening = async (org_id, filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/organization-screening`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getOneOrganizationScreeningById = async (
  org_id,
  org_screening_id
) => {
  let url = `${WEB_GTWY_API_URL}/organization-screening/${org_screening_id}`;

  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createOrganizationScreeningSearch = async (org_id, data) => {
  let url = `${WEB_GTWY_API_URL}/organization-screening/check`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  let requestData = await createRequest();
  requestData = { ...requestData, ...data, is_monitored: true };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteOrganizationScreeningSearch = async (
  org_id,
  screening_id
) => {
  let url = `${WEB_GTWY_API_URL}/organization-screening/${screening_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateKeyConnectorMap = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/key-connector-map/${obj.key_connector_map_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.weightage = +obj.weightage;
  requestData.connector_id = obj.connector_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSpecificKeyConnectorMap = async (key_connector_map_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/key-connector-map/${key_connector_map_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllKeyConnectorMap = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/key-connector-map`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteKeyConnectorMapById = async (key_connector_map_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/key-connector-map/${key_connector_map_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

// organization-setings

export const getOrgSetting = async (key, org_id, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-setting/${key}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createOrgSetting = async (data, org_id, env = "production") => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-setting`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;

  let requestData = await createRequest();
  requestData = { ...requestData, ...data };

  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllIdVerification = async (org_id, filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/id-verification`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getIdVerificationReport = async (report_id) => {
  let url = `${WEB_GTWY_API_URL}/id-verification/report/${report_id}`;

  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgIdVerificationReport = async (org_id, data) => {
  const url = `${WEB_GTWY_API_URL}/id-verification/org-report/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  let requesData = await createRequest();
  const response = await axios.post(url, data, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayinMethodCost = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-cost`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addPayinMethodCost = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-cost`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payin_method_class =
    obj.payin_method_class !== "" ? obj.payin_method_class : null;
  requestData.payin_method_group =
    obj.payin_method_group !== "" ? obj.payin_method_group : null;
  requestData.connector_id = obj.connector_id;
  requestData.payin_method_id = obj.payin_method_id || null;
  requestData.currency = obj.currency;
  requestData.static_cost = obj.static_cost || null;
  requestData.percentage_cost = obj.percentage_cost || null;
  requestData.event_name = obj.event_name;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editPayinMethodCost = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-cost/${obj.payin_method_cost_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payin_method_class =
    obj.payin_method_class !== "" ? obj.payin_method_class : null;
  requestData.payin_method_group =
    obj.payin_method_group !== "" ? obj.payin_method_group : null;
  requestData.connector_id = obj.connector_id;
  requestData.payin_method_id = obj.payin_method_id || null;
  requestData.currency = obj.currency;
  requestData.static_cost = obj.static_cost || null;
  requestData.percentage_cost = obj.percentage_cost || null;
  requestData.event_name = obj.event_name;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPayinMethodCost = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-cost/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deletePayinMethodCost = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payin-method-cost/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayoutMethodCost = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-cost`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addPayoutMethodCost = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-cost`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payout_method_class =
    obj.payout_method_class !== "" ? obj.payout_method_class : null;
  requestData.payout_method_group =
    obj.payout_method_group !== "" ? obj.payout_method_group : null;
  requestData.connector_id = obj.connector_id;
  requestData.payout_method_id = obj.payout_method_id || null;
  requestData.currency = obj.currency;
  requestData.static_cost = obj.static_cost || null;
  requestData.percentage_cost = obj.percentage_cost || null;
  requestData.event_name = obj.event_name;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editPayoutMethodCost = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-cost/${obj.payout_method_cost_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.payout_method_class =
    obj.payout_method_class !== "" ? obj.payout_method_class : null;
  requestData.payout_method_group =
    obj.payout_method_group !== "" ? obj.payout_method_group : null;
  requestData.connector_id = obj.connector_id;
  requestData.payout_method_id = obj.payout_method_id || null;
  requestData.currency = obj.currency;
  requestData.static_cost = obj.static_cost || null;
  requestData.percentage_cost = obj.percentage_cost || null;
  requestData.event_name = obj.event_name;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deletePayoutMethodCost = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-cost/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPayoutMethodCost = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/payout-method-cost/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllFxConversionCost = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-cost`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addFxConversionCost = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-cost`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.connector_id = obj.connector_id;
  requestData.currency = obj.currency || null;
  requestData.static_cost = obj.static_cost || null;
  requestData.cost_in_bps = obj.cost_in_bps || null;
  requestData.event_name = obj.event_name;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editFxConversionCost = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-cost/${obj.fx_conversion_cost_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.connector_id = obj.connector_id;
  requestData.currency = obj.currency;
  requestData.static_cost = obj.static_cost;
  requestData.cost_in_bps = obj.cost_in_bps;
  requestData.event_name = obj.event_name;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteFxConversionCost = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-cost/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getFxConversionCost = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-cost/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgOrPlanSpecificFXMarkup = async (org_id, filterArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/fees/markup`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const downloadIdVerificationReport = async (verification_id, org_id) => {
  let url = `${WEB_GTWY_API_URL}/id-verification/report/${verification_id}/download`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(
    url,
    {
      headers: requestHeaders,
    },
    { responseType: "blob" }
  );
  return response.data;
};

export const generateReport = async (obj) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/regulatory-report/?file_type=${obj.file_type}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.interval = obj.interval;
  requestData.report_type = obj.report_category;
  requestData.country = obj.country;
  requestData.start_date = obj.startTime;
  requestData.end_date = obj.endTime;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllRegulatoryReports = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/regulatory-report`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
// partner API's

export const fetchAllPartners = async (filterArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/partner`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPartnerById = async (partner_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/partner/${partner_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createPartnerOrgPlanConfig = async (org_id, plan_group) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/partner/org-plan-config`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(
    url,
    { org_id, plan_group },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const loginPartnerUser = async (data) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/partner-user/bo-admin-login`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.org_id = data.org_id;
  requestData.org_user_id = data.org_user_id;
  requestData.partner_id = data.partner_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const invitePartner = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/partner/invite-partner`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.first_name = obj.first_name;
  requestData.last_name = obj.last_name;
  requestData.org_name = obj.partner_name;
  requestData.org_country = obj.country;
  requestData.email = obj.email;
  requestData.partner_level = obj.partner_level;
  requestData.gca_pricing_plan_id = obj.partner_plan_id;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendPartnerInvitation = async (associated_org_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/partner/resend-invitation-email/${associated_org_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createPartnerCommission = async ({
  scope = "PARTNER",
  ...data
}) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/partner-commission`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.partner_id = data?.partner_id || null;
  requestData.scope = scope;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPartnerCommission = async (filterArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/partner-commission`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getDocumentId = async (report_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/regulatory-report/${report_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const approveRejectAuthorizationLetter = async (
  action,
  org_id,
  data
) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization/authorization-letter/${action}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const requestData = { ...data };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getDownloadLinkUsingDocId = async (doc_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/backoffice-document/${doc_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadRegulatoryReport = async (
  file_type,
  report_file,
  file_name
) => {
  return uploadBackofficeDocument(file_type, report_file, file_name);
};

export const uploadBackofficeDocument = async (file_type, file, file_name) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/backoffice-document`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.file_type = file_type;
  requestData.file_name = file_name;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  const { upload_url, bo_document_id } = response.data.data;
  const uploadResponse = await axios.put(upload_url, file, {
    headers: {
      "Content-Type": file_type,
    },
    redirect: "follow",
  });
  return {
    success: uploadResponse.status === 200,
    bo_document_id,
  };
};

export const updateReport = async (doc_id, backoffice_doc_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/regulatory-report/${doc_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.backoffice_doc_id = backoffice_doc_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyReport = async (doc_id, verification) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/regulatory-report/${doc_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.is_verified = verification;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const copyReport = async (doc_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/regulatory-report/${doc_id}/copy`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchTransactionScreeningSearch = async (
  search_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-screening/${search_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;

  const response = await axios.get(url, { headers: requestHeaders });

  return response.data;
};

export const updateTransactionScreeningSearch = async (
  data,
  search_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-screening/${search_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  let requesData = await createRequest();
  requesData = { ...requesData, ...data };
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const updateTransactionScreeningSearchEntity = async (
  data,
  search_id,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-screening/${search_id}/entities`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  let requesData = await createRequest();
  requesData = { ...requesData, ...data };
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createTransfer = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/transfer`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = {
    beneficiary_org_id: data.beneficiary_org_id,
    beneficiary_virtual_account_id: data?.virtual_account_id,
    beneficiary_currency: data?.beneficiary_currency,
    description: data?.description,
    payout_method_name: data?.payout_method_name,
    sender_amount: +data.sender_amount,
    sender_currency: data.source_currency,
    sender_org_id: data.sender_org_id,
    sender_wallet_id: data?.debit_wallet_id,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const validateTransfer = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/transfer/validate`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = {
    beneficiary_org_id: data.beneficiary_org_id,
    beneficiary_virtual_account_id: data?.virtual_account_id,
    payout_method_name: data?.payout_method_name,
    sender_amount: +data.sender_amount,
    sender_currency: data.source_currency,
    sender_org_id: data.sender_org_id,
    sender_wallet_id: data?.debit_wallet_id,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllTransfers = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/transfer`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchTransferById = async (transfer_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/transfer/${transfer_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateSenderpartyDetail = async (
  payin_id,
  data,
  env = "production"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/${payin_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const requestData = {
    sender_party_detail: data,
  };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBanks = async () => {
  return [];
};

export const addFxConversionMarkup = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-markup`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData = obj;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllFxConversionMarkup = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-markup`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const editFxConversionMarkup = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-markup/${obj.cmp_id}`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  delete obj["cmp_id"];
  requestData = obj;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getFxConversionMarkup = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-markup/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const deleteFxConversionMarkup = async (id) => {
  const url = `${WEB_GTWY_API_URL}/pricing-engine/fx-conversion-markup/${id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateConnectorBalance = async (body, connector_name) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/addBalance`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, body, {
    headers: requestHeaders,
    // params: connector_id,
  });
  return response.data;
};

export const UploadConnectorBalances = async (body, connector_name) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/uploadCsv`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, body, {
    headers: requestHeaders,
    params: connector_name,
  });
  return response.data;
};

export const addBankAccount = async (body) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/addBankAccount`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, body, {
    headers: requestHeaders,
    // params: connector_id,
  });
  return response.data;
};

export const getAllAction = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/action`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createAction = async (values) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/tos/action`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};
export const approveAction = async (action_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/tos/action`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.action_id = action_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBalanceForCurrency = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/currency`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getTestData = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/testData`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBalanceForConnector = async (connector) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/connector_name/${connector}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBalanceForCountry = async (country) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/country/${country}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllConnectorBalance = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/balance`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllData = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/getAllData`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getHistoricalBalance = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/getHistoricalBalance`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgHistoricalBalance = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/banks-configuration/getHistoricalBalance`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getInterfaceConfig = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/interface-congiguration/getInterfaceConfig`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllInterfaceConfig = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/interface-congiguration/getAllInterfaceConfig`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getConnectedAccounts = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/getConnectedAccounts`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSubAccountsCurrencySummary = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/getSubAccountsCurrencySummary`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateAccountDetails = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connector-balance/updateAccountDetails`;
  const requestHeaders = await getRequestHeaders();
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const response = await axios.patch(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const checkIfSubAccountsAllowed = async (connector_name) => {
  //needs to be done through an end-point
  let url = `${WEB_GTWY_API_URL}/tos/support-sub-account/${connector_name}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getGenericSubAccountsInfo = async () => {
  let url = `${WEB_GTWY_API_URL}/tos/getGenericSubAccountsInfo`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getTosConnectorInfo = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/tos/connected-banks`;
  const filterUrl = getFilterQueryUrl(filterQueryArr);
  url = `${url}?${filterUrl}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const postTosConnectorInfo = async (body) => {
  let url = `${WEB_GTWY_API_URL}/tos/connected-banks`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, body, { headers: requestHeaders });
  return response.data;
};

export const postTosAccount = async (org_id, body) => {
  let url = `${WEB_GTWY_API_URL}/tos/account`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;

  const response = await axios.post(url, body, { headers: requestHeaders });
  return response.data;
};

//TOS END

//TOS END
export const fetchAllPayoutMethod = async (
  filterQueryArr = [
    {
      filter_key: "sender_country",
      filter_value: "AU",
    },
    {
      filter_key: "sender_currency",
      filter_value: "AUD",
    },
    {
      filter_key: "beneficiary_country",
      filter_value: "AU",
    },
    {
      filter_key: "beneficiary_currency",
      filter_value: "AUD",
    },
  ]
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-method/all`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateRAstatus = async (partner_id, status) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/partner/agreement/${partner_id}`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.reliance_agreement_approval_status = status;
  requestData.doc_type = "reliance_agreement";
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

//List Manager Routes
export const getAllList = async (filterQueryArr = [], env = "production") => {
  let url = `${WEB_GTWY_API_URL}/backoffice/list-manager`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createList = async (data, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/backoffice/list-manager`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.post(url, data, { headers: requestHeaders });
  return response.data;
};

export const updateList = async (list_id, data, env = "production") => {
  let url = `${WEB_GTWY_API_URL}/backoffice/list-manager/${list_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.patch(url, data, { headers: requestHeaders });
  return response.data;
};

export const getPartnerSetting = async (key, partner_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/partner-setting/${key}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-ptnr-id"] = partner_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updatePartnerSetting = async (key, value, partner_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/partner-setting`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-ptnr-id"] = partner_id;
  const response = await axios.put(
    url,
    {
      key,
      value,
    },
    { headers: requestHeaders }
  );
  return response.data;
};

//risk profile APIs

export const getRiskProfile = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-risk-profile/org/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const updateRiskProfile = async (risk_profile_id, status) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/organization-risk-profile/${risk_profile_id}`;
  const requestHeaders = await getRequestHeaders();
  let requesData = await createRequest();
  requesData["risk_level"] = status;
  const response = await axios.patch(url, requesData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createRiskProfile = async (org_id, status) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-risk-profile`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData["org_id"] = org_id;
  requestData["risk_level"] = status;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resumeSettlementPayout = async (settlement_payout_id, body) => {
  const url = `${WEB_GTWY_API_URL}/torc/settlement-payout/resume-payout-stuck-in-awaiting-final-rate-approval/${settlement_payout_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

export const completeSettlementPayout = async (settlement_payout_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/settlement-payout/${settlement_payout_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(
    url,
    {},
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const fetchAllBroadcastEmail = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/broadcast-email`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getBroadcastEmailById = async (broadcast_email_id) => {
  if (!broadcast_email_id) return;
  let url = `${WEB_GTWY_API_URL}/broadcast-email/${broadcast_email_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  let responseData = response.data;
  return await responseData;
};

// rate limit
export const getRateLimitConfig = async (org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/rate-limit/${org_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const handleRateLimitUpdate = async (values, org_id) => {
  let url = `${WEB_GTWY_API_URL}/backoffice/rate-limit/config`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.put(url, values, { headers: requestHeaders });
  return response.data;
};

export const getNewFxRate = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/fx-rate`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  let requestData = await createRequest();
  requestData.source_amount = +data.source_amount || null;
  requestData.source_currency = data.source_currency || null;
  requestData.target_amount = +data.target_amount || null;
  requestData.target_currency = data.target_currency || null;
  if (data.customer_id) {
    requestData["customer_id"] = data.customer_id;
  }
  if (data.settle_by) {
    requestData.settle_by = data.settle_by;
  }
  requestHeaders["x-env"] = "production";
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

//investment apis

export const fetchAllAssets = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-assets`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const fetchAllHoldings = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const fetchAllHoldingTransactions = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-transaction`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const fetchPortfolioBalnce = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding/organization/portfolio`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchEarningToDate = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding/organization/current-earning-value`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const confirmBuyAsset = async (values) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

export const confirmSellAsset = async (values) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding/sell`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

export const InvestedAmount = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding/organization/current-investment`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getTransferDetails = async (
  source_bank_id,
  target_bank_id,
  beneficiary_currency
) => {
  let url = `${WEB_GTWY_API_URL}/tos/action/transfer-details?source_bank_id=${source_bank_id}&target_bank_id=${target_bank_id}&beneficiary_currency=${beneficiary_currency}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAvailableBalance = async () => {
  let url = `${WEB_GTWY_API_URL}/backoffice/tos/investment-holding/organization/fetch-available-balance`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createInvoiceConfig = async (values) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/invoice-organization-config`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getInvoiceConfigs = async (filterQuerryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/invoice-organization-config/backoffice`;
  if (filterQuerryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQuerryArr);
    if (filterUrl) url = `${url}?${filterUrl}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const getOneInvoiceConfig = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/invoice-organization-config`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateInvoiceConfig = async (
  values,
  invoice_config_id,
  org_id
) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/invoice-organization-config/${invoice_config_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.patch(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

//stables Transaction START

export const createStablesTransaction = async (body) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, body, {
    headers: requestHeaders,
  });
  return response;
};
export const updateRampDetails = async (ramp_id, body) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction/ramps/${ramp_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchAllStablesTransactions = async (filterQuerryArr = []) => {
  const filterUrl = getFilterQueryUrl(filterQuerryArr);
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction/ramps${
    filterUrl ? "?" + filterUrl : ""
  }`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getRampDetail = async (ramp_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction/ramps/${ramp_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const uploadFile = async (bo_document_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction/file-upload`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(
    url,
    {
      bo_document_id,
    },
    { headers: requestHeaders }
  );
  return response.data;
};
export const getStablesTransactionDetail = async (stables_transaction_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction/${stables_transaction_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getStablesTransactionPdf = async (ramp_id, org_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-transaction/get-stables-transaction-pdf/${ramp_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

//stables Transaction END
//Provider START
export const createProvider = async (body) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-provider`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, body, {
    headers: requestHeaders,
  });
  return response;
};
export const updateProvider = async (provider_id, body) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-provider/${provider_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchAllProvider = async (filterQuerryArr = []) => {
  const filterUrl = getFilterQueryUrl(filterQuerryArr);
  const url = `${WEB_GTWY_API_URL}/torc/stables-provider${
    filterUrl ? "?" + filterUrl : ""
  }`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getProvider = async (ramp_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/stables-provider/${ramp_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
//Provider End

//Create Screening Comments
export const createScreeningSearchComment = async (search_id, remark) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-screening/${search_id}/comment`;
  const requestHeaders = await getRequestHeaders();
  const body = { comment_data: remark };
  const response = await axios.post(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getScreeningSearchComment = async (search_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction-screening/${search_id}/comment`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateCustomerBackoffice = async (
  customer_id,
  is_va_allowed,
  org_id,
  env = "production"
) => {
  const url = `${WEB_GTWY_API_URL}/torc/${customer_id}/backoffice`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  requestHeaders["x-org-id"] = org_id;
  const body = { is_va_allowed: is_va_allowed };
  const response = await axios.patch(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

//LIMIT AT GLANCE START
export const getAllOrgLimit = async (filterQuerryArr = []) => {
  const filterUrl = getFilterQueryUrl(filterQuerryArr);
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-limit?${filterUrl}`;
  const request_headers = await getRequestHeaders();
  const response = await axios.get(url, { headers: request_headers });
  return response.data;
};

export const createOrgLimit = async (body) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-limit`;
  const request_headers = await getRequestHeaders();
  const response = await axios.post(url, body, { headers: request_headers });
  return response.data;
};

export const patchOrgLimit = async (org_limit_id, body) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-limit/${org_limit_id}`;
  const request_headers = await getRequestHeaders();
  const response = await axios.patch(url, body, { headers: request_headers });
  return response.data;
};

export const deleteOrgLimit = async (org_limit_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-limit/${org_limit_id}`;
  const request_headers = await getRequestHeaders();
  const response = await axios.delete(url, { headers: request_headers });
  return response.data;
};

export const getOrgLimit = async (org_limit_id) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/organization-limit/${org_limit_id}`;
  const request_headers = await getRequestHeaders();
  const response = await axios.get(url, { headers: request_headers });
  return response.data;
};
//LIMIT AT GLANCE END

export const verifyTransactionStatus = async (data) => {
  const url = `${WEB_GTWY_API_URL}/backoffice/connector/verify-status`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
    params: data,
  });
  return response.data;
};
